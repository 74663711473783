/**
 * Adds banner and panel on the page for cookie management by user. Updates Google Tag Manager consent settings.
 */
const cpCookieConsent = (() => {
    let props = {
        cookieName: 'cpCookieConsent',
        cookieDelimiter: ',',
        cookieKeyValueDelimiter: ':',
        cookieDeniedValue: 'denied',
        cookieGrantedValue: 'granted',
        updateAllKey: 'all',
        lang: 'en',
        showDeclineAllButton: false,
        showManageButton: true,
        consentStateDefault: {}, // initial value sets in init() method
        managerCategories: [
            {
                id: 'necessary',
                title: 'Strictly necessary',
                // consentVars: ['security_storage', 'functionality_storage'],// TODO: Decide necessary or not
                description: `These cookies are required to support the core functionality of our website.`,
                disabled: true
            },
            {
                id: 'performance',
                title: 'Performance',
                consentVars: [
                    'analytics_storage',
                    // 'personalization_storage'
                ],
                description: `These cookies enable us to analyse the use of the website in order to measure and improve performance.`
            },
            {
                id: 'marketing',
                title: 'Marketing',
                consentVars: ['ad_storage', 'ad_user_data', 'ad_personalization'],
                description: `These cookies are used to measure and improve the performance of our marketing campaigns.`
            },
            // {
            //     id: 'uncategorized',
            //     title: 'Uncategorized',
            //     description: `lorem ipsum uncategorized`
            // }
        ],
        strings: {
            en: {
                buttonAcceptAll: 'Accept all cookies',
                buttonOnlyNecessary: 'Only use necessary',
                buttonManage: 'Customise cookies',
                buttonApply: 'Apply',
                message: 'Lorem ipsum&nbsp;<a href="#">Cookie Policy</a>',
                mClose: 'Close cookie manager',
                mContentText: '<h3>Lorem, ipsum</h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita voluptatibus quae architecto consequuntur officiis. Quas autem libero praesentium optio, eveniet sequi et laudantium doloribus reiciendis, excepturi, sed error soluta magnam!',
                mAccordionOption1: 'Performance',
            },
            ru: {}
        },
        classes: {
            container: 'cp-cookie-consent',
            wrapper: 'cp-cookie-consent__wrapper',
            content: 'cp-cookie-consent__content',
            text: 'cp-cookie-consent__text',
            buttons: 'cp-cookie-consent__buttons',
            button: 'cp-cookie-consent-button',
            footer: 'cp-cookie-consent__footer',
            footerWrapper: 'cp-cookie-consent__footer-wrapper',
            footerLink: 'cp-cookie-consent__footer-link',
            manager: 'cp-cookie-consent-manager',
            mPanel: 'cp-cookie-consent-manager__panel',
            mPanelControls: 'cp-cookie-consent-manager__panel-controls',
            mPanelControlsClose: 'cp-cookie-consent-manager__panel-controls-close',
            mPanelContent: 'cp-cookie-consent-manager__panel-content',
            mPanelContentText: 'cp-cookie-consent-manager__panel-content-text',
            mPanelList: 'cp-cookie-consent-manager__list',
            mPanelListRow: 'cp-cookie-consent-manager__list-row',
            mPanelListCollapse: 'cp-cookie-consent-manager__list-collapse',
            mPanelListRowControl: 'cp-cookie-consent-manager__list-row-control',
            mPanelListRowControlJs: 'js-cp-cookie-consent-switch',
            mPanelSwitch: 'switch',
            mPanelSlider: 'slider',
            mAccordionTrigger: 'cp-cookie-consent-manager-dd-trigger',
            mAccordionTriggerJs: 'js-cp-cookie-consent-accordion-trigger',
            mFooter: 'cp-cookie-consent-manager__footer',
            consentScrollLocked: 'cp-consent-scroll-locked',
            active: 'active'
        },
        ids: {
            acceptAllButton: 'cpCookieConsentAcceptAll',
            onlyNecessaryButton: 'cpCookieConsentOnlyNecessary',
            manageButton: 'cpCookieConsentManage',
            applySettingsButton: 'cpCookieMangerApply',
            mAcceptAllButton: 'cpCookieConsentAcceptAllManager',
            managerCloseButton: 'cpCookieManagerClose'
        },
        footerLinks: []
    };
    let consentCookieValueStored = '';
    let consentCookieValueCurrent = '';
    let consentState = {};
    let showManager = false;
    let bannerRef = null;
    let managerRef = null;
    const storeDays = Math.floor(365 / 2);
    const waitForUpdate = 500;

    /**
     * Sets Google Tag cookie consent defaults.
     * Code should be called inside <head> tag before gtag initialisation.
     * @param {*} newProps - configuration object, modifies existing props. Needs additional parsing for multi-level objects.
     * @example 
     * // Start script
     * cpCookieConsent.init();
     */
    const init = (newProps) => {
        props.consentStateDefault = {
            ad_storage: props.cookieDeniedValue,
            analytics_storage: props.cookieDeniedValue,
            ad_user_data: props.cookieDeniedValue,
            ad_personalization: props.cookieDeniedValue,
            personalization_storage: props.cookieDeniedValue,
            functionality_storage: props.cookieDeniedValue,
            security_storage: props.cookieDeniedValue,
        };
        if (newProps) {
            const strings = { ...props.strings };
            props = { ...props, ...newProps };
            if (newProps.strings) {
                Object.keys(newProps.strings).forEach(key => {
                    const element = newProps.strings[key];
                    strings[key] = { ...strings[key], ...element };
                });
                props.strings = strings;
            }
            if (newProps.managerCategories) {
                props.managerCategories = [...newProps.managerCategories];
            }
        }
        consentCookieValueStored = readCookie(props.cookieName) ?? '';
        consentCookieValueCurrent = consentCookieValueStored;
        setGtagConsentDefaults();
    };

    /**
     * Updates tag manager and renders consent banner. Should be called before closing </body> tag.
     * @example 
     * // Start script
     * cpCookieConsent.init();
     */
    const initUI = () => {
        updateConsentState();
        updateGtagConsent();
        renderBanner();
    }

    const readCookie = (cookieName) => {
        const nameEQ = cookieName + '=';
        const ca = document.cookie.split(';');
        for (const element of ca) {
            let c = element;
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    const createCookie = (cookieName, value, days) => {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }
        document.cookie = cookieName + '=' + value + expires + '; path=/';
    };

    const clearAllPaths = (window, cookieBase) => {
        let p = window.location.pathname.split('/');
        window.document.cookie = cookieBase + '; path=/';
        while (p.length > 0) {
            window.document.cookie = cookieBase + '; path=' + p.join('/');
            p.pop();
        };
    };

    const deleteAllCookies = () => {
        let cookies = window.document.cookie.split('; ');
        for (const element of cookies) {
            let encodedCookieName = encodeURIComponent(element.split(";")[0].split("=")[0]);
            let cookieBase = encodedCookieName + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            if (encodedCookieName === props.cookieName) {
                continue;
            }
            clearAllPaths(window, cookieBase);
            let d = window.location.hostname.split('.');
            while (d.length > 0) {
                if (encodedCookieName === props.cookieName) { break; };
                clearAllPaths(window, cookieBase + '; domain=' + d.join('.'));
                d.shift();
            }
        }
    };

    /**
     * Should be called only one time per page
     */
    const setGtagConsentDefaults = () => {
        consentState = props.consentStateDefault;
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () { dataLayer.push(arguments); }
        window.gtag('set', 'wait_for_update', waitForUpdate);
        updateConsentState();
    };

    /**
     * Makes gtag api call
     */
    const updateGtagConsent = () => {
        window.gtag('consent', 'update', consentState);
        dataLayer.push({ 'event': 'cookie_consent_update' });
    };

    /**
     * Updates current consent state in code, not in gtag
     */
    const updateConsentState = () => {
        let newConsentState = props.consentStateDefault;
        if (!consentCookieValueStored) {
            // Set default state if cookie is not set
            consentState = newConsentState;
            // window.gtag('consent', 'default', consentState);
        } else {
            newConsentState = props.consentStateDefault;
            // Create and set state by specified categories
            props.managerCategories.forEach(category => {
                const storedValue = getCategoryValueByName(category.id, consentCookieValueStored);
                if (
                    storedValue
                    && (storedValue === props.cookieGrantedValue || storedValue === props.cookieDeniedValue)
                    && category?.consentVars?.length
                ) {
                    category.consentVars.forEach(consentVar => {
                        newConsentState[consentVar] = storedValue;
                    });
                }
            });
            consentState = newConsentState;
            // updateGtagConsent();
        }
    };

    const updateManagerCookieState = (newCategoryName, newValue) => {
        // Update current values
        const values = props.managerCategories.map((category, index) => {
            let categoryName = category.id;
            let categoryValue = props.cookieDeniedValue;
            if (index === 0) {
                categoryValue = props.cookieGrantedValue;
            }
            const currentValue = getCategoryValueByName(categoryName, consentCookieValueCurrent);
            if (currentValue) {
                categoryValue = currentValue;
            }
            if (newCategoryName && newValue && category.id === newCategoryName) {
                categoryValue = newValue;
            }
            // Update all categories by same value
            if (newCategoryName === props.updateAllKey && newValue) {
                categoryValue = category.id !== props.managerCategories[0].id ? newValue : props.cookieGrantedValue;
            }
            return `${categoryName}${props.cookieKeyValueDelimiter}${categoryValue}`;
        });
        consentCookieValueCurrent = values.join(`${props.cookieDelimiter}`);
    };

    const getCategoryValueByName = (categoryName, str) => {
        const categories = new RegExp(`(${categoryName})(:)(?<value>[\\w]*)`, 'g');
        const result = categories.exec(str);
        return result?.groups?.value;
    }

    const checkIfCookieSet = () => {
        return consentCookieValueStored && (
            consentCookieValueStored !== '0'
            && consentCookieValueStored !== '1'
            && consentCookieValueStored !== '2'
        );
    }

    const toggleBodyScroll = (close) => {
        if (close) {
            return document.body.classList.remove(props.classes.consentScrollLocked);
        }
        document.body.classList.toggle(props.classes.consentScrollLocked);
    };

    const closeManager = (ref) => {
        showManager = false;
        toggleBodyScroll(true);
        ref?.classList.remove(props.classes.active);
        renderManager();
    };

    const openManager = () => {
        showManager = true;
        toggleBodyScroll();
        renderManager();
    };

    const onAcceptAllClick = (event) => {
        event.preventDefault();
        updateManagerCookieState(props.updateAllKey, props.cookieGrantedValue);
        consentCookieValueStored = consentCookieValueCurrent;
        createCookie(props.cookieName, consentCookieValueStored, storeDays);
        closeManager(managerRef);
        renderBanner();
        updateConsentState();
        updateGtagConsent();
    }

    const onDeclineAllClick = (event) => {
        event.preventDefault();
        deleteAllCookies();
        updateManagerCookieState(props.updateAllKey, props.cookieDeniedValue);
        consentCookieValueStored = consentCookieValueCurrent;
        createCookie(props.cookieName, consentCookieValueStored, storeDays);
        closeManager(managerRef);
        renderBanner();
        updateConsentState();
        updateGtagConsent();
    }

    const bindBannerEvents = (ref) => {
        if (!ref) { return; }
        const acceptAllButton = ref.querySelector('#' + props.ids.acceptAllButton);
        const onlyNecessaryButton = ref.querySelector('#' + props.ids.onlyNecessaryButton);
        const manageButton = ref.querySelector('#' + props.ids.manageButton);
        if (acceptAllButton) {
            acceptAllButton.addEventListener('click', (e) => {
                onAcceptAllClick(e);
            });
        }
        if (onlyNecessaryButton) {
            onlyNecessaryButton.addEventListener('click', (e) => {
                onDeclineAllClick(e);
            });
        }
        if (manageButton) {
            manageButton.addEventListener('click', (e) => {
                e.preventDefault();
                // Set accept all default state if no cookie found
                if (!checkIfCookieSet()) {
                    props.managerCategories.forEach(category => {
                        updateManagerCookieState(category.id, props.cookieGrantedValue);
                    })
                } else {
                    updateManagerCookieState();
                }
                openManager();
            });
        }
    };

    const bindManagerEvents = (ref) => {
        if (!ref) { return; }
        const managerCloseBytton = document.getElementById(props.ids.managerCloseButton);
        const accordionTriggers = ref.querySelectorAll(`.${props.classes.mAccordionTriggerJs}`);
        const applySettingsButton = ref.querySelector(`#${props.ids.applySettingsButton}`);
        const acceptAllButton = ref.querySelector(`#${props.ids.mAcceptAllButton}`);
        const switches = ref.querySelectorAll(`.${props.classes.mPanelListRowControlJs}`);
        if (managerCloseBytton) {
            managerCloseBytton.addEventListener('click', (e) => {
                closeManager(ref);
            });
        }
        if (accordionTriggers) {
            accordionTriggers.forEach(trigger => {
                trigger.addEventListener('click', (e) => {
                    e.preventDefault();
                    const element = e.currentTarget;
                    element.classList.toggle(props.classes.active);
                    const collapseTarget = element.getAttribute('data-target');
                    const collapse = ref.querySelector(`#${collapseTarget}`);
                    if (!collapse) { return; }
                    collapse.classList.toggle(props.classes.active);
                });
            })
        }
        if (switches) {
            switches.forEach(item => {
                item.addEventListener('change', (e) => {
                    e.preventDefault();
                    const element = e.currentTarget;
                    const value = element.checked ? props.cookieGrantedValue : props.cookieDeniedValue;
                    updateManagerCookieState(element.name, value);
                });
            })
        }
        if (acceptAllButton) {
            acceptAllButton.addEventListener('click', (e) => {
                onAcceptAllClick(e);
            });
        }
        if (applySettingsButton) {
            applySettingsButton.addEventListener('click', (e) => {
                deleteAllCookies();
                // Store settings in cookie
                consentCookieValueStored = consentCookieValueCurrent;
                // Close panel
                closeManager(managerRef);
                // Hide banner
                renderBanner();
                // Create consent cookie
                createCookie(props.cookieName, consentCookieValueStored, storeDays);
                // Update Google Tag
                updateConsentState();
                updateGtagConsent();
            });
        }
    };

    const renderManagerGroups = (lang) => {
        const panelManagerList = document.createElement('div');
        panelManagerList.classList.add(props.classes.mPanelList);
        const targetBase = 'cpConsentAccordionTarget';
        if (!props.managerCategories?.length) {
            return panelManagerList;
        }
        props.managerCategories.forEach((category, index) => {
            const panelManagerListRow = document.createElement('div');
            panelManagerListRow.classList.add(props.classes.mPanelListRow);
            const panelManagerDdTrigger = document.createElement('button');
            panelManagerDdTrigger.type = 'button';
            panelManagerDdTrigger.classList.add(props.classes.mAccordionTrigger);
            panelManagerDdTrigger.classList.add(props.classes.mAccordionTrigger, props.classes.mAccordionTriggerJs);
            panelManagerDdTrigger.setAttribute('data-target', `${targetBase}${index}`);
            panelManagerDdTrigger.innerText = category.title;
            const panelManagerControl = document.createElement('div');
            panelManagerControl.classList.add(props.classes.mPanelListRowControl);
            const cookie = readCookie(props.cookieName);
            const categoryValue = getCategoryValueByName(category.id, consentCookieValueCurrent);
            let isCategoryChecked = categoryValue === props.cookieGrantedValue ? 'checked' : '';
            panelManagerControl.innerHTML = `
                <label class="${props.classes.mPanelSwitch}">
                    <input type="checkbox" class="${props.classes.mPanelListRowControlJs}" name="${category.id}" ${isCategoryChecked}>
                    <span class="${props.classes.mPanelSlider}"></span>
                </label>
            `;
            const panelManagerCollapse = document.createElement('div');
            panelManagerCollapse.classList.add(props.classes.mPanelListCollapse);
            panelManagerCollapse.id = `${targetBase}${index}`;
            panelManagerCollapse.innerHTML = category.description ?? '';

            panelManagerListRow.append(panelManagerDdTrigger);
            if (!category.disabled) {
                panelManagerListRow.append(panelManagerControl);
            }
            panelManagerList.append(panelManagerListRow);
            panelManagerList.append(panelManagerCollapse);
        });
        return panelManagerList;
    };

    const renderManager = () => {
        const lang = props.lang;
        if (managerRef) {
            document.body.removeChild(managerRef);
            managerRef = null;
        }
        if (!showManager) { return; }
        const container = document.createElement('div');
        container.classList.add(props.classes.manager, props.classes.active);
        const panel = document.createElement('div');
        panel.classList.add(props.classes.mPanel);
        const panelControls = document.createElement('div');
        panelControls.classList.add(props.classes.mPanelControls);
        const panelControlsClose = document.createElement('button');
        panelControlsClose.type = 'button';
        panelControlsClose.id = props.ids.managerCloseButton;
        panelControlsClose.classList.add(props.classes.mPanelControlsClose);
        panelControlsClose.setAttribute('aria-label', props.strings[lang].mClose);
        panelControlsClose.setAttribute('title', props.strings[lang].mClose);
        const panelContent = document.createElement('div');
        panelContent.classList.add(props.classes.mPanelContent);
        const panelContentText = document.createElement('div');
        panelContentText.classList.add(props.classes.mPanelContentText);
        panelContentText.innerHTML = props.strings[lang].mContentText;
        const panelManagerList = renderManagerGroups(lang);
        const panelFooter = document.createElement('div');
        panelFooter.classList.add(props.classes.mFooter);
        const panelFooterButtonApply = document.createElement('button');
        panelFooterButtonApply.classList.add(props.classes.button);
        panelFooterButtonApply.textContent = props.strings[lang].buttonApply;
        panelFooterButtonApply.id = props.ids.applySettingsButton;
        const panelFooterButtonAcceptAll = document.createElement('button');
        panelFooterButtonAcceptAll.className = props.classes.button;
        panelFooterButtonAcceptAll.textContent = props.strings[lang].buttonAcceptAll;
        panelFooterButtonAcceptAll.id = props.ids.mAcceptAllButton;

        panelControls.append(panelControlsClose);
        panel.append(panelControls);
        panelContent.append(panelContentText);
        panelContent.append(panelManagerList);
        panel.append(panelContent);
        panelFooter.append(panelFooterButtonApply);
        panelFooter.append(panelFooterButtonAcceptAll);
        panel.append(panelFooter);
        container.append(panel);
        document.body.append(container);
        managerRef = container;
        bindManagerEvents(managerRef);
    };

    const renderBanner = () => {
        const hasCookie = checkIfCookieSet();
        if (hasCookie) {
            if (bannerRef) {
                document.body.removeChild(bannerRef);
                bannerRef = null;
            }
            return;
        }
        if (!hasCookie) {
            ;
            deleteAllCookies();
        }
        const container = document.createElement('div');
        container.classList.add(props.classes.container);
        const wrapper = document.createElement('div');
        wrapper.classList.add(props.classes.wrapper);
        const content = document.createElement('div');
        content.classList.add(props.classes.content);
        const text = document.createElement('div');
        const textContainer = document.createElement('div');
        textContainer.innerHTML = props.strings[props.lang].message;
        text.classList.add(props.classes.text);
        const buttons = document.createElement('div');
        buttons.classList.add(props.classes.buttons);
        const footer = document.createElement('div');
        footer.classList.add(props.classes.footer);
        const footerWrapper = document.createElement('div');
        footerWrapper.classList.add(props.classes.footerWrapper);
        const footerLink = document.createElement('a');
        footerLink.classList.add(props.classes.footerLink);
        footerLink.textContent = props.strings[props.lang].cookiePolicyLink;
        // Add buttons
        for (let i = 0; i < 3; i++) {
            const button = document.createElement('button');
            button.classList.add(props.classes.button);
            button.type = 'button';
            const strings = [
                props.strings[props.lang].buttonAcceptAll,
                props.strings[props.lang].buttonOnlyNecessary,
                props.strings[props.lang].buttonManage
            ];
            const ids = [
                props.ids.acceptAllButton,
                props.ids.onlyNecessaryButton,
                props.ids.manageButton
            ];
            button.textContent = strings[i];
            button.id = ids[i]
            // Hide decline all button
            if (props.showManageButton && !props.showDeclineAllButton && i === 1) {
                continue;
            }
            // Hide manage button
            if (!props.showManageButton && props.showDeclineAllButton && i === 2) {
                continue;
            }
            buttons.append(button);
        }
        // Add footer links
        if (props.footerLinks?.length) {
            props.footerLinks.forEach((link) => {
                const element = link;
                const footerLink = document.createElement('a');
                footerLink.classList.add(element.class || props.classes.footerLink);
                footerLink.href = element.href;
                footerLink.textContent = element.text;
                footerWrapper.append(footerLink);
            });
        }
        text.append(textContainer);
        content.append(text);
        content.append(buttons);
        wrapper.append(content);
        container.append(wrapper);
        if (props.footerLinks?.length) {
            footerWrapper.append(footerLink);
            footer.append(footerWrapper);
        }
        container.append(footer);
        document.body.append(container);
        bannerRef = container;
        bindBannerEvents(bannerRef);
        renderManager();
    };

    return {
        init: init,
        initUI: initUI
    }
})();